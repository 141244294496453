import { extendTheme } from '@chakra-ui/react';
import { FONT_FAMILY } from '@/constants/fonts';
import { BUTTON_COLOR, BG_COLOR, TEXT_COLOR } from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    headingAlternative: FONT_FAMILY.headingAlternative,
    body: FONT_FAMILY.body,
    jost: `'Jost', sans-serif;`, // hardcoded font family for qrcode purpose
  },
  colors: {
    bgAlternative: BG_COLOR.alternative,
    bgFooter: BG_COLOR.footer,
    bgPrimary: BG_COLOR.primary,
    bgSecondary: BG_COLOR.secondary,
    btnMainColor: BUTTON_COLOR.primary,
    mainColor: BG_COLOR.primary,
    mainColorText: TEXT_COLOR.primary,
    mainColorText2: TEXT_COLOR.primary2,
    secondaryColorText: TEXT_COLOR.secondary,
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_COLOR.primary,
      bgSecondary: BG_COLOR.secondary,
    },
  },
};

export default extendTheme(theme);
