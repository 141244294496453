/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////
export { DEFAULT_PROPS } from './default-props';

/////////////////////////////////////////////
// COLOR CONFIGURATION
/////////////////////////////////////////////
export const BG_COLOR = {
  primary: '#FFF',
  secondary: '#323030',
  alternative: '#F4F4F4',
  footer: '#003674',
};

export const TEXT_COLOR = {
  primary: '#000',
  primary2: '#545454',
  secondary: '#fff',
};

export const NAVIGATION_COLOR = {
  base: '#fff',
  icon: '#000',
};

export const BUTTON_COLOR = {
  primary: '#000',
};

export const INPUT_COLOR = {
  background: '#ADABAC',
  color: TEXT_COLOR.secondary,
};
